@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.wrapper {
  margin-bottom: spacing(1.5);

  @include devices(md-up) {
    margin-bottom: spacing(2);
  }
}

.caption {
  display: flex;
  align-items: center;
  margin-bottom: spacing(0.75);
  flex-wrap: wrap;

  @include devices(md-up) {
    margin-bottom: spacing(2);
  }
}

.name {
  font-size: rem(16);
  word-wrap: break-word;
  margin-bottom: 0;
  margin-right: spacing(1);

  @include devices(md-up) {
    font-size: rem(28);
    margin-right: spacing(2);
  }
}
