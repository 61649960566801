@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.rootCentered {
  @include devices(md-up) {
    justify-content: center;
  }
}

.priceWrapper {
  display: inline-flex;
  position: relative;
  align-items: baseline;
}

.priceWithPromo {
  margin-right: 6px;
}

.priceWithPromoCentered {
  @include devices(md-up) {
    margin-left: 3px;
    margin-right: 3px;
  }
}

.promotionRoot {
  flex: 1 1 100%;
  max-width: 305px;
}

.promotionBodyLineBreak {
  @include devices(s-down) {
    white-space: normal;
  }
}
