@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.promotionHint {
  font-style: italic;
  font-weight: var(--font-weight-bold);
  color: var(--secondary-main);
  font-size: rem(14);
  margin-bottom: spacing(1);

  @include devices(md-up) {
    font-size: rem(16);
    margin-bottom: spacing(2.5);
  }
}

.promotionHintTitle {
  font-style: italic;
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(1);
  font-size: rem(14);

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.promotionHintSubtitle {
  font-size: rem(14);

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.promotionBox {
  padding: spacing(1.5);
  border: 1px solid var(--primary-main);
  margin-bottom: spacing(1.5);

  @include devices(md-up) {
    margin-bottom: spacing(3.5);
    padding: spacing(1.5, 2.5);
  }
}
