@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.status {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: spacing(0, 0.3);
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  min-width: 20px;
  min-height: 20px;
  border-radius: 20px;
  font-size: rem(12);
  border: 2px solid var(--common-white);

  @include devices(md-up) {
    border-width: 3px;
  }
}

.online {
  background-color: var(--success-main);
}

.offline {
  background-color: var(--contextual-colors-ui-secondary);
}

.busy {
  background-color: var(--contextual-colors-attention);
}
