@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyStateIcon {
  width: 46px;
  height: 46px;

  @include devices(sm-up) {
    width: 48px;
    height: 48px;
  }
}

.text {
  font-weight: var(--font-weight-medium);
  margin: spacing(2);
  text-align: center;

  @include devices(sm-up) {
    font-size: rem(16);
  }
}

.button {
  width: 100%;
  max-width: 300px;
}
