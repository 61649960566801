@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(1);
  justify-content: space-between;
}

.desktopColumn {
  @include devices(sm-up) {
    flex-direction: column;
    gap: spacing(1);
  }
}

.red {
  color: red;
}

.productContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.productType {
  display: flex;
  color: var(--text-primary);
  margin-right: spacing(1.5);
}

.freeProduct {
  color: var(--secondary-main);
}

.pricePrefix {
  margin-right: spacing(0.5);
}
