@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.wrapper {
  display: flex;
  flex: 1;
  background-color: var(--background-default);
  border-radius: 4px;
  padding: spacing(1.6, 2);
  justify-content: space-between;
  margin-bottom: spacing(3.75);

  @include devices(md-up) {
    padding: spacing(3.75, 7);
    margin-bottom: spacing(7);
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  font-size: rem(15);
  font-weight: var(--font-weight-bold);
  margin-bottom: spacing(1);

  @include devices(md-up) {
    font-size: rem(19);
  }
}

.body {
  color: var(--text-secondary);
  font-size: rem(12);

  @include devices(md-up) {
    font-size: rem(14);
  }
}

.link {
  display: block;
  color: var(--primary-main);
  font-weight: var(--font-weight-bold);
  margin-top: spacing(1);

  @include devices(md-up) {
    display: inline-block;
    margin-top: 0;
  }
}

.mediaColumn {
  margin-left: spacing(1.25);
  justify-content: center;
  flex: 0 1 100%;

  @include devices(md-up) {
    margin-left: spacing(2.5);
  }
}

.media {
  max-width: spacing(8.75);
  position: relative;

  @include devices(md-up) {
    max-width: spacing(13.75);
  }
}

.image {
  width: 100%;
  height: auto;
}
