@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.tooltip {
  top: 2px;

  @include devices(md-up) {
    left: 16px;
  }
}

.icon {
  align-self: center;
  width: 16px;
  height: 16px;
  color: var(--contextual-colors-ui-primary);
  margin-left: 4px;
  padding: 0;
}
