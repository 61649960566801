@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.buttonsList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonsListItem {
  margin-bottom: spacing(1);

  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  max-width: 144px;

  @include devices(md-up) {
    max-width: 159px;
  }
}

.buttonLabel {
  font-size: rem(14);

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.sectionContainerIcon {
  height: auto;
  margin-bottom: spacing(1);
  margin-top: spacing(2);

  @include devices(md-up) {
    margin-top: spacing(0);
  }
}

.sectionContainerBody {
  display: flex;
  flex-direction: column;
  width: auto;
  font-size: rem(14);

  @include devices(md-up) {
    font-size: rem(16);
    margin-bottom: spacing(3.2);
  }
}

.emptyStateBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.emptyStateIcon {
  width: 55px;
  height: 55px;
  margin-top: spacing(3);
  margin-bottom: spacing(3);

  @include devices(md-up) {
    margin-top: spacing(6);
  }
}

.panelTitle {
  padding: spacing(0, 2);
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(2);

  @include devices(md-up) {
    padding: spacing(0);
    font-size: rem(16);
    max-width: 100%;
  }
}

.emptyStateButton {
  width: 100%;
  max-width: 300px;
  max-height: 40px;
  font-size: rem(16);
  margin-bottom: spacing(2);

  @include devices(md-up) {
    margin-bottom: spacing(5);
  }
}

.freeCallButton {
  width: 100%;
  max-width: 300px;
  max-height: 40px;
  font-size: rem(16);
  margin-bottom: 0;
  margin-top: spacing(1.5);

  @include devices(md-up) {
    margin-top: spacing(1.5);
  }
}

.freeCallPromotion {
  width: auto;
  margin-top: spacing(2.5);

  @include devices(md-up) {
    margin: spacing(3) auto 0;
    min-width: 473px;
  }
}

.promotionRow {
  width: 100%;
  display: flex;
  justify-content: space-around;
  border: 1px solid var(--secondary-main);
  border-top: none;
  border-radius: spacing(0, 0, 0.5, 0.5);
  padding: spacing(1.5);
  margin-bottom: spacing(1);
  margin-top: spacing(-0.75);
}

.promotionText {
  text-align: center;
  font-size: rem(14);
  max-width: 116px;
}

.withMargin {
  margin-top: spacing(2);
}

.firstColumn {
  padding-left: spacing(9);
  border-bottom-color: var(--contextual-colors-neutral);
}

.th {
  color: var(--text-secondary);
  font-weight: var(--font-weight-bold);
}

.tableRow {
  & > td {
    border-bottom-color: var(--contextual-colors-neutral);
  }
}

.dateColumn {
  display: flex;
  align-items: center;
}

.desktopPromoRoot {
  display: inline-flex;
}

.promotionBody {
  font-weight: var(--font-weight-bold);
}

.warnIcon {
  width: 18px;
  height: 18px;
  margin-left: spacing(1.5);
}

.promotionItem {
  margin-left: spacing(1.5);
  margin-right: spacing(1.5);
  margin-bottom: spacing(2.2);

  &:first-child {
    margin-top: spacing(2);
  }
}

.promotionRowMobile {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--secondary-main);
  border-top: none;
  border-radius: spacing(0, 0, 0.5, 0.5);
  padding: spacing(1.5);
}

.promotionTextWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
