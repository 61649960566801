@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.expertBox {
  display: flex;
  flex: 1;
}

.expertDetails {
  display: inline-flex;
}

.avatarWrapper {
  width: 52px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;

  @include devices(md-up) {
    width: 54px;
  }
}

.avatar {
  height: 60px;

  @include devices(md-up) {
    height: 63px;
  }

  .onlineStatus {
    min-width: 15px;
    min-height: 15px;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: spacing(1);

  @include devices(md-up) {
    display: none;
  }
}

.itemPrice {
  font-size: rem(12);

  span {
    color: var(--secondary-main);
  }
}

.productList {
  flex-direction: column;
  min-width: 77px;
  max-width: 77px;
  flex-grow: 1;

  @include devices(md-up) {
    align-items: flex-end;
  }
}

.mobileProductList {
  max-width: 100%;
  padding-left: 10px;
}
