@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: spacing(1.5);
}

.item {
  width: 100%;
}

.productButton {
  width: 100%;

  @include devices(sm-down) {
    padding-left: spacing(0.5);
    padding-right: spacing(0.5);
  }
}

.withPrice {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
