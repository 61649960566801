@use "@styles/partials/functions" as *;

.root {
  display: flex;
  align-items: center;
  padding: spacing(0.25, 1);
  border-radius: 20px;
  font-weight: var(--font-weight-medium);
  font-size: rem(12);
  line-height: 16px;
}

.available {
  color: var(--success-main);
  background: var(--contextual-colors-bg-status-available);
}

.notAvailable {
  color: var(--contextual-colors-ui-secondary);
  background: var(--contextual-colors-bg-main);
}

.busy {
  color: var(--contextual-colors-attention);
  background: var(--contextual-colors-bg-obstacle);
}
