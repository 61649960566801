@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  display: flex;
  width: 100%;
  background-color: var(--contextual-colors-bg-secondary-accent);
  padding: 13px 13px 12px 12px;
  border-radius: 4px;
  margin-top: spacing(2);

  &:hover {
    filter: brightness(99%);
  }

  @include devices(sm-up) {
    padding: 6px 28px 7px 12px;
    align-items: center;
  }
}

.img {
  min-width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-right: spacing(1);

  @include devices(sm-up) {
    margin-right: spacing(1.5);
  }
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @include devices(sm-up) {
    flex-direction: row;
    align-items: center;
  }
}

.text {
  font-size: rem(14);
  line-height: 18.52px;

  @include devices(sm-up) {
    width: 100%;
    font-size: rem(16);
    line-height: 22.88px;
  }
}

.astrotv {
  font-weight: var(--font-weight-bold);
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.linkText {
  font-size: rem(12);
  line-height: 16.21px;
  color: var(--text-secondary);
  white-space: nowrap;

  @include devices(sm-up) {
    font-size: rem(14);
    line-height: 18.52px;
  }
}

.icon {
  margin-left: spacing(1.5);
  height: 100%;
  display: flex;
  color: var(--contextual-colors-ui-secondary);
}
