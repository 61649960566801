@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 8px;
}

.statsItem {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.withSignAndLang {
  width: 100%;

  @include devices(md-up) {
    width: auto;
  }
}

.iconWrapper {
  display: inline-flex;
  align-items: center;
  font-size: rem(12);
  font-weight: var(--font-weight-bold);
  text-transform: capitalize;

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: spacing(0.5);

  @include devices(md-up) {
    margin-right: spacing(1);
  }
}
